import React, { Fragment } from 'react';
import Cookies from 'js-cookie';
import AcknowledgeButton from 'components/cookies_notice/acknowledge_button';

const CookieNotice = () => {
  const showNotice = false; //Cookies.get('acknowleged_cookies') !== 'yes'
  return (
    <Fragment>
      {showNotice && (
        <div className="cookieNotice">
          <div className="p-6 text-center " style={{}}>
            <div className="leading-tight lg:leading-normal lg:mr-4">
              We use cookies for key functions of our site. For more
              information, read our{' '}
              <a
                className="text-white text-underline hover:text-teal-lighter"
                href="/cookies"
              >
                Cookie&nbsp;Policy
              </a>
              .
            </div>
            <div className="mt-4">
              <AcknowledgeButton />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CookieNotice;
